@import "~normalize.css";
@import "~@blueprintjs/core/lib/css/blueprint.css";
@import "~@blueprintjs/icons/lib/css/blueprint-icons.css";
@import "~@blueprintjs/core/lib/scss/variables";

.constraint-picker {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.logo {
    height: 192px;
    width: 192px;
}

.flex {
    display: flex;
}

.auto-complete {
    width: 100%;
}

.challenge-container {
    margin: auto;
    max-width: 500px;
}

.challenge-description {
    margin: 8px auto;
    display: block;
    width: 100%;
}

.challenge-submit {
    width: 100%;
}
.full-width {
    width: 100%;
}
.challenge-callout {
    margin: $pt-grid-size 0;
}

.solution-editor {
    width: 100%;
    padding: $pt-grid-size;
    display: flex;
    justify-content: center;
    margin: 2 * $pt-grid-size 0;
}


.solution-editor .challenge-container {
    margin: 0px;
}

.squad-editor {
    width: 440px;
    height: 680px;
    background-image: url('./field_outline.png');
    background-size: contain;
    background-repeat: no-repeat;
    position: relative;
    max-width: 98%;
    margin: auto;
}

.constraint-label {
    margin: 0.5 * $pt-grid-size 0;
}

.player-picker {
    margin: auto;
}

.player-icon {
    display:flex;
    flex-direction: column;
    position: absolute;
}

.marker {
    height: 40px;
    width: 40px;
    border-radius: 100%;
    background-color: green;
    margin: 4px auto;
}

.violation-list {
    margin: 8px 0px;
}

.violation-list-item {
    margin: 4px 0px;
}

.error-text {
    text-align: center;
    margin: 4px 0;
}

.text-align-center {
   text-align: center;
}

.bp3-input:disabled {
    color: $dark-gray2;
}

.mg-small {
    margin: $pt-grid-size;
}

.divider {
    margin: 0 4 * $pt-grid-size;
}

.progress-bar {
    margin-top: 4px;
}

.share-button-container {
    cursor: pointer;
}

.page-title {
    margin: 0px auto;
}

.home-page-navbar {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 2*$pt-grid-size 0;
}

.mg-y-small {
    margin: $pt-grid-size 0;
}

.mg-x-small {
    margin: 0 $pt-grid-size;
}

.about {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1 * $pt-grid-size auto;
    width: 50%;
}

.inline {
    display: flex;
    align-items: center;
}

.exclude-color {
    color: $red1;
}

.rules-renderer-header {
    display: flex;
    align-content: center;
    justify-content: space-between;
}

.rules-renderer-header span {
    align-self: center;
}

.rules-renderer {
    margin-bottom:  2 * $pt-grid-size;
    margin-right:  2 * $pt-grid-size;
    min-width: 350px;
}

.include-color {
    color: $green1;
}

.steps {
    margin: 1 * $pt-grid-size auto;
    width: 50%;

    h1 {
        text-align: center;
    }

    .row {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .column {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin: 2 * $pt-grid-size 0;
    }

    .column.button {
        align-items: center;
        margin: $pt-grid-size auto;
        width: 100%;
    }

    .number {
        display: flex;
        align-items: center;
        min-width: 60px;
        min-height: 60px;
        border-radius: 100%;
        background-color: $blue2;
        margin: 0 $pt-grid-size;
    }

    .number div {
        margin: 0px auto;
        color: $white;
    }

    .center {
        align-self: center;
    }
}



@media only screen and (max-width: 600px) {
    .challenge-container {
        margin: auto;
        width: 95%;
    }
}
/* Smartphones (portrait and landscape) ----------- */
@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    .solution-editor {
        display: flex;
        flex-direction: column;
    }

    .challenge-container {
        width: 100%;
        padding: $pt-grid-size;
    }

    .page-title h1 {
        font-size: $pt-font-size-large;
    }

     .bp3-navbar-heading h3 {
        font-size: $pt-font-size-small;
    }

    .squad-editor {
        height: 580px;
    }

    .steps {
        width: 100%;
        padding: $pt-grid-size;

        .center img {
            max-width: 100%;
        }
    }

    .about {
        width: 90%;
    }
}
